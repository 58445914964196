import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';

function DashboardBulkHomePage(props) {
  const app = React.useContext(AppContext.Context);

  React.useEffect(() => {
    const _fetchData = async () => {};
    if (app.staffUser) {
      _fetchData();
    }
  }, [app.staffUser]);

  return (
    <Wrapper>
      <h2>大宗首頁</h2>

      <p></p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  & h2 {
    margin-bottom: 16px;
  }

  & > .section {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 10px #cecece;
    padding: 20px;
    margin-bottom: 20px;

    & > .actions {
      display: flex;
      margin-bottom: 10px;
    }
    & > .content {
      & .block {
        & .title {
          font-size: 18px;
        }
        & .value {
          font-size: 18px;
          & .hint {
            font-size: 14px;
            color: grey;
          }
        }
      }
    }
  }
`;

export default DashboardBulkHomePage;
